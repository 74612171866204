<template>
  <v-card>
    <v-toolbar flat>
      <v-tabs
        v-model="tabs"
        fixed-tabs
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          class="primary--text"
        >
          {{$vuetify.lang.t('$vuetify.department.selectAdminUser')}}
        </v-tab>

        <v-tab
          class="primary--text"
        >
          {{$vuetify.lang.t('$vuetify.department.addAdminUser')}}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tabs">
      <v-tab-item
        key="1"
      >
        <user-list
          :dep-info="depInfo"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </v-tab-item>
      <v-tab-item
        key="2"
      >
        <admin-user-base-form
          :dep-info="depInfo"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>

<script>
  export default {
    components: {
      UserList: () => import('./UserList'),
      AdminUserBaseForm: () => import('./AdminUserBaseForm')
    },

    props: {
      depInfo: {
        type: Object,
        default: () => null
      },
      depId: {
        type: Number,
        default: () => null
      }
    },

    data () {
      return {
        tabs: null
      }
    },

    methods: {

      handleCancel () {
        this.$emit('cancel', true )
      },

      handleSubmit () {
        this.$emit('submit', true )
      }
    }
  }
</script>
